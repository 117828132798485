import React, { Component } from "react"
import { graphql } from "gatsby"

import { getTranslations } from "../components/helpers/helpers";
import Layout from "../components/layout";
import SEO from "../components/seo";

import "../styles/contact.scss"


class Contact extends Component {


    render() {
        const { data: { pageData, arrunadaPicture }, pageContext: { lang } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, "contact");

        return (
            <Layout mainClassName="contact" data={{}} noActiveMenu={true} pageContext={this.props.pageContext} translations={translations}>

                <SEO title={page.frontmatter.title} description={page.frontmatter.summary} lang={lang ? lang : "en" }/>

                <div
                    className="contact-container general-container initial-padding"
                    style={{backgroundImage: `url(${arrunadaPicture.childImageSharp.fluid.srcWebp})`}}
                >

                    <div className="contact-top">
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.summary }}></h1>                        
                    </div>

                    <div className="is-html with-headers" dangerouslySetInnerHTML={{ __html: page.html }}></div>    

                </div>

            </Layout>
        )
    }
}

export default Contact


export const query = graphql`
    query ContactQuery  {
        arrunadaPicture: file(relativePath: { eq: "arrunada-picture-contact.jpg" }) {
            childImageSharp {
                fluid(quality: 85, maxWidth: 550) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "contact" }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                    }
                    id
                    html

                }
            }
        }

    }
`